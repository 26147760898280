<template>
  <div style='padding-bottom: 50px'>
    <div
      style='display: flex;flex-direction: row;gap: 10px;justify-content: space-between;align-items: center;margin-bottom: 5px'>
      <div style='line-height: 30px;'>
        <span style='color: #ff3176'>营收绩效目标</span>
        <span style='color: #1a1a1a;'>与完成情况</span>
        <span class='group-name'>【{{ searchCondition.group_name || '' }}】</span>
      </div>

      <div style='line-height: 30px;text-align: right'>
        <div style='display: inline-block;margin-bottom: 4px'>
          <LogPanel ref='refLogPanel' module-name='pa_group_revenues' v-if='hasAuthority("pa_dept_log")'></LogPanel>
        </div>
        <export-btn ref='export' @export='exportExcel' :can-export='hasAuthority("pa_dept_export")'
                    :lint-title='`${activeMap[activeName]||"-"}绩效导出`'
                    style='margin-left: 5px'>导出
        </export-btn>
        <!--        <el-button v-if='hasAuthority("pa_dept_kol_setting")' type='danger' icon='el-icon-setting'-->
        <!--                   @click='showKolSetting'>-->
        <!--          红人管理-->
        <!--        </el-button>-->
        <!--        <el-link type="primary" icon='el-icon-refresh' @click='getList'>刷新</el-link>-->
      </div>
    </div>

    <div class='default-table'
         v-loading='loadingStatus'
         :element-loading-text='loadingText'
         element-loading-spinner='el-icon-loading'>
      <el-table
        :data='tblData'
        :span-method='objectSpanMethod'
        @row-contextmenu='rowContextmenu'
        @header-contextmenu='headerContextmenu'
        :cell-class-name='cellClassName'
        style='width: 100%'
        border>
        <el-table-column
          prop='nickname'
          label='红人昵称'
          align='center'
          min-width='100'>
          <template slot-scope='{row}'>
            <artist-nickname :info='row' />
          </template>
        </el-table-column>
        <el-table-column
          prop='platform_name'
          label='平台'
          align='center'
          min-width='80'>
          <template slot-scope='{row}'>
            <span>{{ row.platform_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop='income_type'
          label='收入分类'
          align='center'
          min-width='90'>
          <template slot-scope='{row,column,$index}'>
            <span :title='`${$index}/${tblLen}`'>{{ row[column.property] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop='average_value'
          label='上季月营收均值'
          min-width='110' header-align='center' align='center'>
          <template slot-scope='{row,column,$index}'>
            <span :title='`${$index}/${tblLen}`'>{{ valFormat(row[column.property]) }}</span>
          </template>
        </el-table-column>
        <el-table-column v-for='(month,index) in quarterlyMonths' :key='index' :label='`${month}月`'
                         align='center'>
          <el-table-column
            :prop='`quotient_${month}`'
            label='系数'
            min-width='100' header-align='center' align='center'>
            <editable-cell
              v-if='editMode&& !($index===tblLen-2)'
              :show-input='row.editMode'
              slot-scope='{$index,row,column}'
              v-model='row[calcColProp(column.property, $index, month)]'
              @change='editVal(row,calcColProp(column.property, $index, month),$index)'
              :is-input-number='false'>
                          <span slot='content'>
                            <span v-if='$index===tblLen-1'
                                  :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                                {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                              </span>
                             <span v-else>
                             {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
                             </span>
                             <i class='el-icon-edit-outline default-edit-icon'></i>
                          </span>
            </editable-cell>
            <template slot-scope='{$index}' v-else-if='$index===tblLen-2'>/</template>
            <template slot-scope='{row,column,$index}' v-else>
              <span v-if='$index===tblLen-1'
                    :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                {{ userPermissions.indexOf('pa_bonus_view') > -1 ? valFormat(row[calcColProp(column.property, $index, month)]) : '--'
                }}
              </span>
              <span v-else>
                {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :prop='`flag_value_${month}`'
            label='目标'
            min-width='100' header-align='center' align='center'>
            <editable-cell
              v-if='editMode && !($index===tblLen-2)'
              :show-input='row.editMode'
              slot-scope='{$index,row,column}'
              v-model='row[calcColProp(column.property, $index, month)]'
              @change='editVal(row,calcColProp(column.property, $index, month),$index)'
              :is-input-number='true'>
                          <span slot='content'>
                            <span v-if='$index===tblLen-1'
                                  :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                                {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                              </span>
                             <span v-else>
                             {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
                             </span>
                             <i class='el-icon-edit-outline default-edit-icon'></i>
                          </span>
            </editable-cell>
            <template slot-scope='{row,column,$index}' v-else>
              <span v-if='$index===tblLen-1'
                    :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                  {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
              </span>
              <span v-else>
                {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :prop='`real_value_${month}`'
            label='完成'
            min-width='100' header-align='center' align='center'>
            <editable-cell
              v-if='editMode&& ($index===tblLen-1)'
              :show-input='row.editMode'
              slot-scope='{$index,row,column}'
              v-model='row[calcColProp(column.property, $index, month)]'
              @change='editVal(row,calcColProp(column.property, $index, month),$index)'
              :is-input-number='true'>
                          <span slot='content'>
                           <span v-if='$index===tblLen-1'
                                 :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                                {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                              </span>
                             <span v-else>
                             {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
                             </span>
                            <!--                            <i class='el-icon-edit-outline default-edit-icon'></i>-->
                          </span>
            </editable-cell>
            <template slot-scope='{row,column,$index}' v-else>
                <span v-if='$index===tblLen-1'
                      :class='[$index===tblLen-1&&row[calcColProp(column.property, $index, month)]>0?"bonus-gt0":""]'>
                 {{ showBonusView ? valFormat(row[calcColProp(column.property, $index, month)]) : '--' }}
                </span>
              <span v-else>
               {{ valFormat(row[calcColProp(column.property, $index, month)]) }}
              </span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div class='flex-sb'>
        <div>&nbsp;</div>
        <!--      核算和确认发布-->
        <CheckRevenuePanel v-if='hasAuthority("pa_dept_checked")' :performance-type='activeName' ref='refCheckPanel'
                           @changed='changedCheck'></CheckRevenuePanel>
        <div>
          <el-button
            v-if='searchCondition.release_status==="N"&& userPermissions.indexOf("pa_dept_publish")>-1'
            type='primary' size='mini' @click='saveConfirm'
            :disabled='commitLoading' :loading='commitLoading' style='margin-left: 20px'>
            确认发布
          </el-button>
        </div>
      </div>
    </div>


    <div>
      <RevenueKolSummary :org-data='dataList' :quarterly-months='quarterlyMonths'></RevenueKolSummary>
    </div>

    <QuotientEdit ref='refPaQuotientEdit' @savedEvent='updateQuotientCol'></QuotientEdit>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { numberFormat } from '@/utils/utils'
import EditableCell from '@/components/EditableCell'
import ExportBtn from '@/components/export/ExportBtn'
import CheckRevenuePanel from '@/pages/pa/dept/CheckRevenuePanel'
import LogPanel from '@/components/logs/LogPanel'
import RevenueKolSummary from './RevenueKolSummary'
import QuotientEdit from '@/pages/pa/components/QuotientEdit'

export default {
  name: 'PaDeptRevenue',
  components: { QuotientEdit, RevenueKolSummary, LogPanel, CheckRevenuePanel, ExportBtn, EditableCell },
  data() {
    return {
      activeName: 'revenue',
      activeMap: { 'trend': '涨粉', 'revenue': '营收' },
      searchCondition: {
        release_status: 'Y',
        year: null,
        quarter: 0,
        dept_ids: [],
        group_id: null,
        group_name: null
      },
      tipsList: [
        '目标值 = 上季月均值 * 系数（若系数为“/”，则可手动输入目标值，前提：有录入权限）',
        '上季涨粉均值：求上季度三个月的新增粉丝数/3，并四舍五入取整',
        '数据录入：数据录入后，实时保存，但是其他人不可见，需要发布后才能同步给其他人',
        '数据确认：1)【确认发布】后数据，其他人即可看到最新数据 2)【确认发布】后若继续修改数据，需要重新点击发布其人才能看到最新数据',
        '设置考核：1) 单个设置，右键“红人昵称/平台”，可进行配置；2) 整组考核设置，点击右上角“考核设置”',
        '批量修改系数：在编辑页面，右键列“系数”，可进行修改',
        '数据版本：已发布/编辑中（有系数录入权限的人才能看[编辑中]的数据）',
        '自营电商-完成值： 电商数据-店铺数据的月度总支付金额 * 0.03（系数），即：月总支付金额：10000，则完成值： 300（10000*0.03）。'
      ],
      showIndex: false,//显示序号
      loadingStatus: false,
      showEditIcon: true,
      commitLoading: false,
      editMode: false,
      edit_mode: 'add',
      // searchCondition: {
      //   months: []
      // },
      bonus_items: [],//团队奖金
      dataList: [],
      mergeFields: ['nickname', 'platform_name'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      fixFields: ['group_name', 'platform_name', 'income_type', 'average_value'],
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      calcFields: [],
      bonusRow: { nickname: '团队奖金', platform_name: '团队奖金', income_type: '团队奖金', average_value: '团队奖金' },//团队奖金行的可编辑列
      currentRow: {},
      currentColumn: {},
      dialogQuotientVisible: false,
      formQuotient: {
        quotient: 0,
        month: 0,
        year: 0,
        group_id: ''
      },
      mode: 'drawer',
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '90%',
        show_footer: false
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      logSetting: {
        visible: false
      }, performanceSetting: {
        visible: false
      },
      checkMonthList: [],
      //表单检验字段
      formCheck: {
        year: '年份',
        quarter: '季度',
        artist_id: '红人ID',
        nickname: '红人昵称',
        platform_code: '平台代号',
        platform_name: '平台名称',
        dept_id: '考核部门',
        dept_name: '考核部门',
        income_code: '收入分类',
        income_type: '收入分类'
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    showBonusView() {
      return this.userPermissions.indexOf('pa_dept_bonus') > -1
    },
    groupName() {
      return this.searchCondition.group_name
    },
    loadingText() {
      return `【${this.searchCondition.year}】年第【${this.searchCondition.quarter}】季度的营收绩效目标数据加载中...`
    },
    quarterlyMonths() {
      const quarter = this.searchCondition.quarter

      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3]
    },
    tblData() {
      if (this.dataList.length > 0) {
        let calcRow = this.calcCol()

        //合计数据-初始化
        let appendRows = [{
          nickname: '本组总营收',
          platform_name: '本组总营收',
          income_type: '本组总营收', ...calcRow
        }]
        // 追加团队奖金
        let bonusRow = this.bonusRow
        if (this.bonus_items.length > 0) {
          let row = {}
          this.bonus_items.forEach((item) => {
            row[`amount_${item.month}`] = item.amount
          })
          bonusRow = {
            nickname: '团队奖金',
            platform_name: '团队奖金',
            income_type: '团队奖金',
            average_value: '团队奖金', ...row
          }
        }
        appendRows.push({ ...bonusRow })

        return [...this.dataList, ...appendRows]
      }

      return []
    },
    tblLen() {
      return this.tblData.length
    },
    //合并单元格的行
    mergeRowIndex() {
      if (this.dataList) {
        let arr = [this.dataList.length, this.dataList.length + 1]
        return arr
      } else {
        return []
      }
    }
    // formQuotientTitle() {
    //   return `批量修改【${ this.formQuotient.year }年${ this.formQuotient.month }月】【${ this.searchCondition.group_name }】营收绩效系数`
    // }
  },
  methods: {
    show(searchCondition) {
      this.searchCondition = searchCondition
      this.editMode = searchCondition.release_status === 'N'
      this.getList()
    },
    calcColProp(prop, $index, month) {
      // console.log(prop)
      if (this.tblData) {
        if ($index === this.tblLen - 1) {
          //最后一行:团队奖金
          return `amount_${month}`
        } else if ($index === this.tblLen - 1) {
          //倒数第2行:本组的总涨粉
          return prop
        }
      }
      return prop
    },
    valFormat(value) {
      if (value !== '/')
        return numberFormat(value, 2, '.', ',', 'round')

      return value
    },
    moneyFormat(value) {
      if (value !== '/')
        return numberFormat(value, 2, '.', ',', 'round')

      return value
    },
    // 保存单元格值
    async saveCell(postData) {
      // 检测必填
      let checkPass = true
      Object.keys(this.formCheck).some((key) => {
        if (!postData[key]) {
          this.$notify.warning(`${this.formCheck[key]} 必传`)
          checkPass = false
          return false
        }
      })

      // 校验未通过，不发起请求
      if (!checkPass) {
        return
      }

      return await this.$api.savePaRevenueDeptData(postData)
    },
    // eslint-disable-next-line
    editVal(row, colName, rowIndex) {
      //团队奖金数据同步，重新计算表格数据的时候要使用
      if (colName.indexOf('amount_') > -1) {
        // this.bonusRow[colName] = row[colName];
        this.saveBonus(row, colName)
        return
      }

      // console.log(`当前操作第${rowIndex},列${colName},isNaN:${isNaN(row[colName])}`)
      if (this.mergeRowIndex.indexOf(rowIndex) > -1) {
        return
      }
      if (!row.artist_id) {
        return
      }
      let saveData = {
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter,
        artist_id: row.artist_id,
        nickname: row.nickname,
        platform_code: row.platform_code,
        platform_name: row.platform_name,
        dept_id: row.dept_id,
        dept_name: row.dept_name,
        income_code: row.income_code,
        income_type: row.income_type
      }
      // console.log('editVal', saveData, row)
      const val = row[colName]
      if (colName.indexOf('quotient_') > -1) {
        let arr = colName.split('quotient_')
        let month = Number(arr[1] || 0)
        if (month >= 1 && month <= 12) {
          saveData['month'] = month
          // let syncField = `quotient_${saveData['month']}`
          if (val !== '/') {
            if (!isNaN(val)) {
              saveData['quotient'] = val
              saveData['flag_value'] = val * row['average_value']
              this.dataList[rowIndex][`flag_value_${month}`] = saveData['flag_value']
              this.saveCell(saveData)
            }
          } else {
            saveData['quotient'] = -1
            this.saveCell(saveData)
          }
        }
      } else if (colName.indexOf('flag_value_') > -1) {
        let arr = colName.split('flag_value_')
        let month = Number(arr[1] || 0)
        if (month >= 1 && month <= 12) {
          saveData['month'] = month
          saveData['flag_value'] = val
          this.saveCell(saveData)
        }
      }
    },

    /**
     * 保存-团队奖金
     * @param row
     * @param colName
     * @returns {Promise<void>}
     */
    async saveBonus(row, colName) {
      let arr = colName.split('_')
      let month = arr[1]
      await this.$api.saveBonus({
        search_condition: this.searchCondition,
        data: { type: 'revenue', amount: row[colName], month: month }
      })
    },

    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field])
          condition[field] = this.searchCondition[field]
      })

      return condition
    },
    async getList() {
      //当前已核算完成的月份
      // await this.getCheckMonths()
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.year && searchCondition.quarter) {
        this.getCalcFields()
      }
      if (!(searchCondition.year && searchCondition.quarter && searchCondition.group_id)) {

        return false
      }

      this.loadingStatus = true
      let { list, bonus_items, checked_months } = await this.$api.getPaDeptRevenueDeptData(searchCondition)
      this.loadingStatus = false
      this.checkMonthList = checked_months || []
      this.bonus_items = bonus_items
      this.dataList = list || []
      this.forDataList() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
      this.$nextTick(() => {
        this.$refs['refCheckPanel'].show(this.searchCondition, checked_months)
      })
    },
    forDataList: function() {
      const batch_set_map = {}
      this.dataList.forEach((item, index) => {
        // 合并单元格
        this.rowspan(index)

        // 生成批量设置目标配置
        if (item.platform_code && item.income_code) {
          const key_batch_set_map = `${item.income_code}-${item.platform_code}`
          if (batch_set_map[key_batch_set_map] === undefined) {
            batch_set_map[key_batch_set_map] = {
              platform_name: item.platform_name,
              platform_code: item.platform_code,
              income_code: item.income_code,
              income_type: item.income_type,
              val: null
            }
          }
        }
      })
      this.$emit('update:batch-map', Object.values(batch_set_map))
    },
    // ============= 合并单元格 =================
    rowspan(index) {
      const fields = this.mergeFields //合并的字段
      fields.forEach((field) => {
        if (index === 0) {
          this.spanObj[field] = []
          this.spanObj[field].push(1)
          this.positionObj[field] = 0
        } else {
          // 合并数据：若和上一行的数据相同，则该字段合并行数加1
          if (this.dataList[index][field] === this.dataList[index - 1][field]) {
            if (field === 'platform_name') {
              //平台列，特殊处理
              if (this.dataList[index]['nickname'] === this.dataList[index - 1]['nickname']) {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              } else {
                this.spanObj[field].push(1)
                this.positionObj[field] = index
              }
            } else {
              this.spanObj[field][this.positionObj[field]] += 1
              this.spanObj[field].push(0)
            }

          } else {
            this.spanObj[field].push(1)
            this.positionObj[field] = index
          }
        }
      })
    },
    // 表格合并行

    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.tblData && this.tblLen > 2) {
        //末尾查询一行合计：
        if (rowIndex === this.tblLen - 1) {
          // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
          if (columnIndex < 4) {
            if ((columnIndex + 1) % 4 === 0) {
              return [1, 4]
            } else {
              return [0, 0]
            }
          } else if ((columnIndex + 1) % 3 === 0) {
            return [1, 3]
          } else {
            return [0, 0]
          }
        } else if (rowIndex === this.tblLen - 2) {
          // 本组总涨粉：
          if (columnIndex < 2) {
            return [0, 0]
          } else if (columnIndex === 2) {
            return [1, 3]
          } else {
            return [1, 1]
          }
        } else {
          const fields = this.mergeFields
          if (column.property && fields.indexOf(column.property) > -1) {
            const _row = this.spanObj[column.property][rowIndex]
            const _col = _row > 0 ? 1 : 0
            return {
              rowspan: _row,
              colspan: _col
            }
          }
        }
      }

    },
    getCalcFields() {
      this.calcFields = []
      this.calcFields.push('average_value')

      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.bonusRow = {
          nickname: '团队奖金',
          platform_name: '团队奖金',
          income_type: '团队奖金',
          average_value: '团队奖金'
        }
        this.quarterlyMonths.forEach((month) => {
          this.bonusRow[`amount_${month}`] = 0//团队奖金行数据初始化

          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1
              }
            })
          }
        })
      }
      return calcRow
    },

    // =============== end-合并单元格 ========================
    closeAllMenu() {
      if (this.$refs['refPerformancePlatformsSelect'])
        this.$refs['refPerformancePlatformsSelect'].closeMenu()

      if (this.$refs['refPerformanceSetting'])
        this.$refs['refPerformanceSetting'].closeMenu()
    },
    async rowContextmenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      // console.log(`红人：${row.nickname},平台：${row.platform_name},列：${column.property}`)
      if (this.userPermissions.indexOf('performance_single_setting') > -1) {
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top // container margin left

        const left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          this.left = maxLeft
        } else {
          this.left = left
        }
        const top = e.clientY - offsetTop

        this.closeAllMenu()

        if (['nickname'].indexOf(column.property) > -1) {
          this.$nextTick(() => {
            this.$refs['refPerformancePlatformsSelect'].init(top, left, row)
          })
        } else if (['platform_name'].indexOf(column.property) > -1) {
          this.$nextTick(() => {
            this.$refs['refPerformanceSetting'].init(top, left, row)
          })
        }
      }
    },
    savedPlatformEvent() {
      //修改完红人考核平台
      this.$confirm('红人考核平台已调整，是否刷新数据?', '提示', {
        confirmButtonText: '刷新',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.getList()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      })
    },
    headerContextmenu(column, e) {
      if (this.searchCondition.release_status !== 'N') {
        // 仅支持编辑中的绩效目标支持批量修改
        return false
      }
      this.formQuotient = {}
      e.preventDefault()//阻止元素发生默认的行为
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value') > -1) {
        let arr = column.property.split('_')
        // let month = arr[1]performance_single_setting
        this.formQuotient.month = arr[1]
        this.formQuotient.year = this.searchCondition.year
        this.formQuotient.group_id = this.searchCondition.group_id
        this.formQuotient.group_name = this.searchCondition.group_name
        // this.dialogQuotientVisible = true
        if (column.property.indexOf('quotient_') > -1) {
          this.formQuotient.month = arr[1]
          this.formQuotient.type = 'quotient'
        } else {
          this.formQuotient.month = arr[2]
          this.formQuotient.type = 'flagValue'
        }
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left

        let left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          left = maxLeft
        }

        const top = e.layerY + 100
        this.$nextTick(() => {
          if (this.$refs['refPaQuotientEdit'])
            this.$refs['refPaQuotientEdit'].init(top, left, this.formQuotient)
        })
      }
    },
    //============ 批量修改绩效目标或系数 =================
    async updateQuotientCol(formQuotient) {

      let val = formQuotient.data
      let field = formQuotient.type
      let quotient = formQuotient.quotient
      let month = formQuotient.month

      const promises = this.dataList.map((row, rowIndex) => {
        let colName = `quotient_${month}`
        row[colName] = quotient
        // row[`flag_value_${month}`] = quotient * row['average_value']
        let saveData = {
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter,
          artist_id: row.artist_id,
          nickname: row.nickname,
          platform_code: row.platform_code,
          platform_name: row.platform_name,
          dept_id: row.dept_id,
          dept_name: row.dept_name,
          income_code: row.income_code,
          income_type: row.income_type
        }
        let appendData = {}
        appendData['month'] = month

        if (field === 'quotient') {
          //修改系数列
          if (val !== '/') {
            if (!isNaN(val)) {
              appendData['quotient'] = val
              //目标=系数* 上级季度均值(营收)
              appendData['flag_value'] = val * row['average_value']
              this.dataList[rowIndex][`quotient_${month}`] = appendData['quotient']
              this.dataList[rowIndex][`flag_value_${month}`] = appendData['flag_value']
              return this.saveCell(Object.assign(saveData, appendData))
            }
          } else {
            saveData['quotient'] = -1
            this.dataList[rowIndex][`quotient_${month}`] = appendData['quotient']
            return this.saveCell(Object.assign(saveData, appendData))
          }
        } else if (field === 'flagValue') {
          saveData['quotient'] = -1
          appendData['flag_value'] = val
          this.dataList[rowIndex][`quotient_${month}`] = '/'
          this.dataList[rowIndex][`flag_value_${month}`] = appendData['flag_value']
          return this.saveCell(Object.assign(saveData, appendData))
        } else {
          this.$notify.warning('无效操作')
        }
      })

      await Promise.all(promises)
      this.$message.success('操作成功！')
    },

    editRow(row) {
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `${this.searchCondition.year}年第${this.searchCondition.quarter}季度【${this.searchCondition.group_name}】营收数据-录入`
      this.drawerData.visible = true
      this.drawerData.loading = false
      this.$nextTick(() => {
        this.$refs['refPerformanceRevenuePanel'].init(row, { ...this.searchCondition })
      })

    },
    editTable(row, e) {
      if (this.mode === 'drawer') {
        this.editRow(row)
      } else {
        // this.currentRow = row
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth // container width
        const maxLeft = offsetWidth - menuMinWidth // left boundary
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

        let left = e.clientX - offsetLeft // 15: margin right
        if (left > maxLeft) {
          left = maxLeft
        } else {
          left = left + 100
        }
        const top = 20
        this.$nextTick(() => {
          this.$refs['refPerformanceRevenueEdit'].init(top, left, row, { ...this.searchCondition })
        })
      }


    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.getList()
    },
    showLog() {
      this.logSetting.visible = true
    },
    closeLog() {
      this.logSetting.visible = false
    },
    showSetting() {
      this.performanceSetting.visible = true
    },
    closeSetting() {
      this.performanceSetting.visible = false
      this.getList()
    },
    jumpToDetail(row) {
      const keyword = 'DingTalk'
      if (navigator.userAgent.indexOf(keyword) > -1) {
        // 获取订单配置
        this.$router.push({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
      } else {
        const { href } = this.$router.resolve({
          name: 'artist_trend_chart',
          params: { artist_id: row.artist_id },
          query: { group_name: row.group_name }
        })
        window.open(href, '_blank')
      }
    },
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({ row, column, rowIndex, columnIndex }) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
          // console.log('fieldNeedCheck', row[fieldNeedCheck])
        }
      }
      if (unCheckBool) {
        return 'performance-uncheck'
      }

      //核算完成：
      let checkBool = false
      let months = this.checkMonthList
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        if (months.indexOf(month) > -1) {
          checkBool = true
        }
      }

      // console.log(`col:${ column.property },checkBool:${ checkBool }`)
      if (checkBool) {
        return 'performance-checked'
      } else
        return ''

    },
    async changeCheckMonth(month) {

      let params = {
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        month: month,
        check_status: this.checkMonthList.indexOf(month) > -1 ? 'Y' : 'N'
      }
      let info = await this.$api.setPerformanceCheckedMonth(params)
      if (info === 'ok') {
        this.$notify.success('操作成功')
      }
    },
    async getCheckMonths() {
      let { months } = await this.$api.getPerformanceCheckedMonths({
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        months: this.quarterlyMonths
      })
      //当前已核算完成的月份
      this.checkMonthList = [...months]
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    },
    showQuarterReal() {

    },
    changeEditMode() {
      this.editMode = !this.editMode
      if (this.editMode && this.searchCondition.release_status !== 'N') {
        // this.$notify.warning('切到编辑中的数据')
        this.searchCondition.release_status = 'N'
        this.getList()
      }
    },
    showBatch() {
      this.$emit('handleBatch')
      // let kolRange = []
      // //批量设置
      // if (this.activeName === 'trend') {
      //   kolRange = this.getCurrKolRange()
      //   this.$refs['batchSetPaTrend'].show(this.searchCondition, kolRange)
      // }
    },
    //获取红人集合（用于批量设置限定范围）
    getCurrKolRange() {
      let kolRage = []
      this.dataList.forEach((item) => {
        if (kolRage.indexOf(item.artist_id) === -1)
          kolRage.push(item.artist_id)
      })
      return kolRage
    },
    async exportExcel() {
      //
      try {
        let fileName = `${this.searchCondition.year}年第${this.searchCondition.quarter}季度${this.activeMap[this.activeName]}绩效目标`

        if (this.activeName === 'revenue') {
          // eslint-disable-next-line no-unused-vars
          let response = await this.$api.exportPaGroupRevenueQuarterData(this.searchCondition)
          this.$notify.warning(fileName)
          this.$refs['export'].exportSaveXlsx(response, fileName)
        } else {
          this.$notify.success(this.activeName)
        }

      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        this.$refs.export.hideLoading()
      }
    },
    showKolSetting() {
      this.$emit('handleSetting')
    },
    //确认操作
    saveConfirm() {
      let msg = `确认发布【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的涨粉绩效目标？发布后其他人将能看到最新的数据, 是否继续?`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.publishSaved()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    //发布已保存到数据
    async publishSaved() {
      this.commitLoading = true
      let info = await this.$api.publishPaDeptRevenueDeptData(this.searchCondition)
      this.commitLoading = false
      if (info) {
        let msg = `${this.searchCondition.year}年${this.searchCondition.quarter}季度【${this.searchCondition.group_name}】绩效目标数据发布`
        this.$notify.success(msg)
        this.$emit('changed')
      }
    },
    changedCheck() {
      setTimeout(() => {
        this.getList()
      }, 500)
    }
  }
}
</script>

<style scoped>
.group-name {
  color: var(--primary);
}

.default-edit-icon {
  float: right;
}
</style>
